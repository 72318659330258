/* eslint-disable no-unused-vars */
import React, { useContext, useState, useRef, useEffect } from "react";
import PageTitle from "../../atoms/PageTitle";
import { APPLICATION_ROUTES, PAGE_TITLES } from "../../../constants";
import Layout from "../../templates/Layout";
import Loader from "../../atoms/Loader";
import cs from "classnames";
import classes from "./WritingCompetitionVideoForm.module.scss";
import { StoreContext } from "../../../contexts";
import { ACTIONS } from "../../../store";
import uploadCharacter from "../../../assets/images/upload-character.png";
import jsonToFormData from "../../../utilities/helpers/jsonToFromData";
import { Loader2 } from "lucide-react";
import { observer } from "mobx-react-lite";
import WritingCompetitionLayout from "../../templates/WritingCompetitionLayout";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { formatChildNames } from "../WritingCompetition/WritingCompetition";
import Checkbox from "../../atoms/Checkbox";
import { useCheckbox } from "../../../hooks";
import { NAMES } from "../../../utilities/validation";
import { useTranslation } from "react-i18next";

const WritingCompetitionVideoForm = observer(() => {
  const { authorization, writingcompetition, common } = useContext(StoreContext);
  const getAction = ACTIONS.WRITING_COMPETITON.GET_SUBMISSION;
  const history = useHistory();
  // State for uploaded files and validation errors
  const [scriptFile, setScriptFile] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  const [errors, setErrors] = useState({ script: "", video: "", checkbox: "" });

  // State for filenames
  const [scriptFileName, setScriptFileName] = useState("");
  const [videoFileName, setVideoFileName] = useState("");

  const { t } = useTranslation();
  const THIS_FIELD_IS_REQUIRED = t("forms.messages.this_field_is_required");

  const isNiceSelected = useCheckbox({
    checked: false,
    label: `My characters talk nicely to each other.`,
    value: false,
    validators: [{ name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } }],
  });
  const isEidileSelected = useCheckbox({
    checked: false,
    label: `My characters are tzniusdik and eidile.`,
    value: false,
    validators: [{ name: NAMES.REQUIRED, options: { message: THIS_FIELD_IS_REQUIRED } }],
  });

  // Refs for the hidden file inputs
  const scriptInputRef = useRef(null);
  const videoInputRef = useRef(null);

  // const characterData = common.get("currentCharacter");

  const childNames = formatChildNames(writingcompetition?.submissionData?.selected_childs_object);

  // Validate file type and size
  // Validate file type
  const validateFile = (file, type) => {
    const allowedTypes = {
      script: ["application/pdf", "application/msword", "image/jpeg", "image/png"],
      video: ["video/mp4", "video/mov"],
    };

    if (!file) return "File is required.";
    if (!allowedTypes[type].includes(file.type)) {
      return `Invalid file type. Allowed types: ${allowedTypes[type].join(", ")}`;
    }

    return ""; // Remove the size validation
  };

  // Handlers for file uploads
  const handleFileChange = (event, type) => {
    const file = event.target.files[0];
    const error = validateFile(file, type);

    if (error) {
      setErrors((prev) => ({ ...prev, [type]: error }));
    } else {
      setErrors((prev) => ({ ...prev, [type]: "" }));
      if (type === "script") {
        setScriptFile(file);
        setScriptFileName(file.name); // Set the filename for the script
      }
      if (type === "video") {
        setVideoFile(file);
        setVideoFileName(file.name); // Set the filename for the video
      }
    }
  };

  const submitScript = (scriptFile, videoFile, isEidile, isNice) => {
    const exFormData = jsonToFormData({
      data: {
        script: scriptFile,
        video: videoFile,
        is_eidile: isEidile,
        is_nice: isNice,
        status: "submitted",
      },
    });

    writingcompetition[`${ACTIONS.WRITING_COMPETITON.SUBMIT_SCRIPT}`](exFormData);
  };

  const handleSubmit = () => {
    const areFilesUploaded = scriptFile && videoFile;
    const areCheckboxesChecked = isNiceSelected.checked && isEidileSelected.checked;

    let errorMessage = "";

    if (!areFilesUploaded && !areCheckboxesChecked) {
      errorMessage = "Please upload the files and check off all the boxes.";
    } else if (!areFilesUploaded) {
      errorMessage = "Please upload files.";
    } else if (!areCheckboxesChecked) {
      errorMessage = "Please check off all the boxes.";
    }

    setErrors(() => ({
      script: !scriptFile ? "Please upload script file." : "",
      video: !videoFile ? "Please upload Video file." : "",
      checkbox: errorMessage,
    }));

    if (areFilesUploaded && areCheckboxesChecked) {
      setErrors(() => ({
        script: "",
        video: "",
        checkbox: "",
      }));

      submitScript(scriptFile, videoFile, isEidileSelected.checked, isNiceSelected.checked);
    }
  };

  useEffect(() => {
    if (writingcompetition[`success${ACTIONS.WRITING_COMPETITON.SUBMIT_SCRIPT}`]) {
      history.push(APPLICATION_ROUTES.WRITINNG_COMPETITION_SUCCESS);
    }
  }, [writingcompetition[`success${ACTIONS.WRITING_COMPETITON.SUBMIT_SCRIPT}`]]);

  useEffect(() => {
    if (authorization.isAuthenticated) {
      writingcompetition[`${ACTIONS.WRITING_COMPETITON.GET_SUBMISSION}`]();
    }
  }, [authorization.isAuthenticated]);

  useEffect(() => {
    if (
      (writingcompetition.submissionData?.video_submission_status == "submitted" &&
        writingcompetition?.submissionData?.status == "submitted") ||
      writingcompetition.submissionData?.video_submission_status == "accepted" ||
      !authorization.isAuthenticated
    ) {
      history.push(APPLICATION_ROUTES.WRITINNG_COMPETITION);
    }
  }, [writingcompetition.submissionData]);

  return (
    <>
      <PageTitle value={PAGE_TITLES.WRITINNG_COMPETITION_FORM} />
      <Layout logotype="maor">
        <WritingCompetitionLayout>
          <>
            {writingcompetition[`isLoading${getAction}`] ||
            authorization.isLoadingSignIn ||
            authorization[`isLoading${ACTIONS.AUTHORIZATION.RESIGN_IN}`] ? (
              <Loader />
            ) : (
              <div className={cs(classes.mainContainer)}>
                <h2 className={cs(classes.heading, classes.grandstanderFamily)}>Welcome Back!</h2>
                <h2 className={cs(classes.redHeading, classes.grandstanderFamily)}>{childNames || ""}</h2>

                <div className={cs(classes.buttonContainer)}>
                  {/* Upload Script File */}
                  <div>
                    <div
                      className={cs(classes.uploadButton, errors.script && classes.borderRed)}
                      onClick={() => scriptInputRef.current.click()}
                    >
                      <input
                        ref={scriptInputRef}
                        type="file"
                        accept=".pdf,.doc,.docx,.jpg,.png"
                        onChange={(e) => handleFileChange(e, "script")}
                        style={{ display: "none" }}
                      />
                      <div className={cs(classes.uploadIcon)}>
                        <img src={uploadCharacter} alt="uploadCharacter" />
                      </div>
                      <div className={cs(classes.uploadButtonText)}>
                        <h2 className={cs(classes.grandstanderFamily)}>Upload Script File</h2>
                        <p>(PDF, Word Document, or Photo)</p>
                      </div>
                    </div>
                    {scriptFileName && (
                      <p className={cs(classes.fileName, classes.grandstanderFamily)}>Uploaded: {scriptFileName}</p>
                    )}
                  </div>

                  {/* Upload Video File */}
                  <div>
                    <div
                      className={cs(classes.uploadButton, errors.video && classes.borderRed)}
                      onClick={() => videoInputRef.current.click()}
                    >
                      <input
                        ref={videoInputRef}
                        type="file"
                        accept="video/mp4,video/mov"
                        onChange={(e) => handleFileChange(e, "video")}
                        style={{ display: "none" }}
                      />
                      <div className={cs(classes.uploadIcon)}>
                        <img src={uploadCharacter} alt="uploadCharacter" />
                      </div>
                      <div className={cs(classes.uploadButtonText)}>
                        <h2 className={cs(classes.heading, classes.grandstanderFamily)}>Upload Video File</h2>
                      </div>
                    </div>
                    {videoFileName && (
                      <p className={cs(classes.fileName, classes.grandstanderFamily)}>Uploaded: {videoFileName}</p>
                    )}
                  </div>
                </div>

                <div className={cs(classes.checkboxContainer)}>
                  <Checkbox
                    color={!errors.checkbox || isNiceSelected.checked ? "lightYellowCheckbox" : "redCheckbox"}
                    {...isNiceSelected}
                  >
                    {isNiceSelected.label}
                  </Checkbox>
                  <Checkbox
                    color={!errors.checkbox || isEidileSelected.checked ? "lightYellowCheckbox" : "redCheckbox"}
                    {...isEidileSelected}
                  >
                    {isEidileSelected.label}
                  </Checkbox>
                </div>

                <p className={cs(classes.submitError)}>
                  {errors.checkbox
                    ? errors.checkbox
                    : errors.script && errors.video
                    ? "Please upload the files."
                    : errors.script
                    ? errors.script
                    : errors.video}
                </p>

                {writingcompetition[`isLoading${ACTIONS.WRITING_COMPETITON.SUBMIT_SCRIPT}`] && (
                  <p className={cs(classes.grandstanderFamily, classes.lightRed)}>
                    We are uploading your file. DO NOT close this window.
                  </p>
                )}

                <button
                  disabled={writingcompetition[`isLoading${ACTIONS.WRITING_COMPETITON.SUBMIT_SCRIPT}`]}
                  className={cs(classes.submitScriptButton, classes.grandstanderFamily)}
                  onClick={handleSubmit}
                >
                  {writingcompetition[`isLoading${ACTIONS.WRITING_COMPETITON.SUBMIT_SCRIPT}`] ? (
                    <Loader2 className={cs(classes.loader)} />
                  ) : (
                    "Submit my Script "
                  )}
                </button>
              </div>
            )}
          </>
        </WritingCompetitionLayout>
      </Layout>
    </>
  );
});

export default WritingCompetitionVideoForm;
