import React from "react";
import ReactVimeo from "@u-wave/react-vimeo";
import cs from "classnames";
import PropTypes from "prop-types";
import classes from "./Player.module.scss";

const Player = ({ additionalClass, page, playerSize, ...props }) => {
  const addClasses = () => {
    if (page == "library") {
      return classes.library;
    }
    if (page == "kids") {
      return classes.kidsLayout;
    }
    if (page == "illuminate") {
      return classes.illuminate;
    }
    if (page == "daily-rebbe-video") {
      return classes.dailyRebbeVideo;
    }
    if (page == "writingCompetition") {
      return classes.writingCompetition;
    }
    return classes[page];
  };

  return (
    <div className={cs(classes.container, addClasses())}>
      <ReactVimeo
        className={cs(classes.player, classes[additionalClass], classes[playerSize])}
        {...props}
        autoplay
        color="#bf3c48"
      />
    </div>
  );
};

Player.propTypes = {
  additionalClass: PropTypes.string,
  page: PropTypes.string,
  playerSize: PropTypes.string,
};

Player.defaultProps = {
  additionalClass: "",
  page: "",
};

export default React.memo(Player);
