import React, { useState } from "react";
import cs from "classnames";
import classes from "./HowDoesItWork.module.scss";
import Player from "../../../../organisms/Player";
import CharacterActive from "../../../../../assets/images/howitworks/character.png";
import ScriptActive from "../../../../../assets/images/howitworks/script.png";
import VotingActive from "../../../../../assets/images/howitworks/voting.png";
import AwardActive from "../../../../../assets/images/howitworks/review.png";

import playIcon from "../../../../../assets/images/competition-play.png";
import { isMobile } from "react-device-detect";
import PropTypes from "prop-types";

const modalStages = [
  {
    stageNumber: 1,
    title: "Character Building",
    icon: CharacterActive,
    description: 'כ"ח כסלו - י"ט טבת',
  },
  {
    stageNumber: 2,
    title: "Script Submission",
    icon: ScriptActive,
    description: 'י"ט טבת - י"א שבט',
  },
  {
    stageNumber: 3,
    title: "Voting",
    icon: VotingActive,
    description: 'י"א שבט - י"ט שבט',
  },
  {
    stageNumber: 4,
    title: "Award Show",
    icon: AwardActive,
    description: 'כ"ה שבט - ט"ז אדר',
  },
];

const HowDoesItWork = ({ url, thumbnail }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayVideo = () => {
    setIsPlaying(true); // Show the Player
  };

  return (
    <div className={cs(classes.howItWorksModal)}>
      <div className={cs(classes.modalVideoSection)}>
        <div className={cs(classes.videoContainer)}>
          {!isPlaying ? (
            <div
              className={cs(classes.videoContent)}
              style={{
                backgroundImage: `url(${thumbnail})`,
              }}
            >
              <button
                className={cs(classes.playButton)}
                onClick={handlePlayVideo} // Trigger playback on click
              >
                <img src={playIcon} alt="Play Icon" />
              </button>
            </div>
          ) : (
            <Player
              video={url} // Pass the video URL
              autoplay
              additionalClass={isMobile ? "mobileHeight" : "desktopHeight"}
              muted={isMobile ? true : false}
              playerSize="regularPlayer"
            />
          )}
        </div>
        <div className={cs(classes.videoInfo)}>
          <div className={cs(classes.videoText)}>
            <h1 className={cs(classes.grandstanderFamily)}>How does the competition work?</h1>
            <p className={cs(classes.videoDescription)}>
              Ever wanted to write a MyMaor episode? For the first time ever, we’re inviting YOU to join the fun.
            </p>
            <p className={cs(classes.videoDescription)}>
              The MyMaor Writing Contest has 3 steps. Watch now to learn how the contest works and become part of the
              adventure!
            </p>
          </div>
        </div>
      </div>
      <div className={classes.stageContainer}>
        {modalStages.map((stage) => (
          <div key={stage.stageNumber} className={classes.stage}>
            <img src={stage.icon} alt={`Stage ${stage.stageNumber} icon`} className={classes.progressIcon} />
            <div className={classes.stageInfo}>
              <p>
                Stage {stage.stageNumber}: <strong>{stage.title}</strong>
              </p>
              <p>{stage.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

HowDoesItWork.propTypes = {
  url: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
};

export default HowDoesItWork;
