import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useHistory } from "react-router-dom";
import { APPLICATION_ROUTES, PARTNERSHIP_STATUS } from "../../../constants";
import Button from "../../atoms/Button";
import Modal from "../Modal";
import classes from "./Restriction.module.scss";
import cs from "classnames";
import PropTypes from "prop-types";
import { getPackageDetails } from "../../../utilities/helpers";
import { StoreContext } from "../../../contexts";
import { useDateFormat } from "../../../hooks";

const Authorization = ({ popup, partnership, authorization }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const onNavigateToPartnersMain = () => {
    history.push(APPLICATION_ROUTES.PARTNERS_PACKAGES);
  };

  let message = null;

  if (partnership.hasDetials) {
    try {
      let expirationDate = new Date(partnership.details["expiration_date"]);
      let planName = partnership.details["plan"]
        .toLowerCase()
        .replace("-", "_");
      const packageDetails = getPackageDetails(planName);
      let plan = t(packageDetails.NAME_TRANSLATION_KEY);

      if (
        partnership.details["status"] == PARTNERSHIP_STATUS.CANCELED &&
        Date.now() > expirationDate
      ) {
        message = (
          <div className={classes.card}>
            <h4 style={{ fontWeight: 400 }} className={classes.title}>
              Your {plan} partnership was <strong>canceled</strong> on{" "}
              {useDateFormat(expirationDate, "T")} due to non-payment.
            </h4>
            <nav className={classes.navigation}>
              <Button onClick={onNavigateToPartnersMain}>Renew Now</Button>
            </nav>
            <p className={classes.text}>{t("pages.library.texts.3")}</p>
          </div>
        );
      } else {
        if (
          partnership.details["status"] != PARTNERSHIP_STATUS.EXPIRED &&
          partnership.details["status"] != PARTNERSHIP_STATUS.FREE
        ) {
          if (
            partnership.details["status"] == PARTNERSHIP_STATUS.PAUSED ||
            partnership.details["status"] == PARTNERSHIP_STATUS.PAST_DUE
          ) {
            message = (
              <div className={classes.card}>
                <h4 className={classes.title} style={{ fontWeight: "400" }}>
                  Your partnership is <strong>past due</strong>, please{" "}
                  <Link
                    className="c-orange"
                    to={APPLICATION_ROUTES.MY_ACCOUNT.PAYMENT_METHOD}
                  >
                    click here
                  </Link>{" "}
                  to update your payment methods.
                </h4>
                <p className={classes.text}>{t("pages.library.texts.3")}</p>
              </div>
            );
          }
        } else if (
          partnership.details["status"] == PARTNERSHIP_STATUS.EXPIRED
        ) {
          message = (
            <div className={classes.card}>
              <h4 className={classes.title} style={{ fontWeight: "700" }}>
                {t("pages.library.titles.2")}
              </h4>
              <p className={classes.text}>
                Your {plan} partnership <strong>expired</strong> on{" "}
                {useDateFormat(expirationDate, "T")}.
              </p>
              <nav className={classes.navigation}>
                <Button onClick={onNavigateToPartnersMain}>Renew Now</Button>
              </nav>
              <p className={classes.text}>{t("pages.library.texts.3")}</p>
            </div>
          );
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  console.log(partnership);
  if (message == null || message == undefined) {
    return <AuthorizationCommon popup={popup} authorization={authorization} />;
  }

  return (
    <Modal opened={popup.isOpened} style={popup.style}>
      <div
        data-name="modal-overlay"
        className={classes.overlay}
        role="button"
        onClick={(event) => {
          if (event.target.matches(`[data-name="modal-overlay"]`))
            popup.onClose();
        }}
      >
        {message}
      </div>
    </Modal>
  );
};

Authorization.propTypes = {
  popup: PropTypes.object,
  partnership: PropTypes.object,
  authorization: PropTypes.object,
};

const AuthorizationKids = ({ popup }) => {
  // const { t } = useTranslation();
  const history = useHistory();

  const onNavigateToSignUp = () => {
    history.push(
      APPLICATION_ROUTES.SIGN_UP +
        "?redirect=" +
        encodeURIComponent(location.pathname + location.search)
    );
  };

  return (
    <Modal opened={popup.isOpened} style={popup.style}>
      <div
        data-name="modal-overlay"
        className={classes.overlay}
        role="button"
        onClick={(event) => {
          if (event.target.matches(`[data-name="modal-overlay"]`))
            popup.onClose();
        }}
      >
        <div className={classes.card}>
          <h4 className={classes.title}>Welcome to Maor!</h4>
          <nav className={classes.navigationGuest}>
            <>
              <button
                className={classes.guestButton}
                onClick={onNavigateToSignUp}
              >
                Sign Up
              </button>{" "}
              for a free account{" "}
              <span className={classes.break}>
                {" "}
                or{" "}
                <NavLink
                  className={cs(classes.link)}
                  to={
                    APPLICATION_ROUTES.SIGN_IN +
                    "?redirect=" +
                    encodeURIComponent(location.pathname + location.search)
                  }
                >
                  Log in
                </NavLink>{" "}
                to enjoy the video.{" "}
              </span>
            </>
          </nav>
          {/* <p className={classes.text}>{t("pages.library.texts.3")}</p> */}
        </div>
      </div>
    </Modal>
  );
};

AuthorizationKids.propTypes = {
  popup: PropTypes.object,
  partnership: PropTypes.object,
  authorization: PropTypes.object,
};

const EmptyPlaylist = ({ popup }) => {
  return (
    <Modal opened={popup.isOpened} style={popup.style}>
      <div
        data-name="modal-overlay1"
        className={classes.overlay}
        role="button"
        onClick={(event) => {
          if (event.target.matches(`[data-name="modal-overlay1"]`))
            popup.onClose();
        }}
      >
        <div className={classes.card}>
          <h4 className={classes.title}>
            Your playlist is currently empty, please add videos to create your
            playlist
          </h4>
        </div>
      </div>
    </Modal>
  );
};

EmptyPlaylist.propTypes = {
  popup: PropTypes.object,
};

const PlanCanceled = ({ popup, plan, partnership, expirationDate }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const onNavigateToPartnersMain = () => {
    history.push(APPLICATION_ROUTES.PARTNERS_PACKAGES);
  };

  let html = null;

  if (
    partnership.details["status"] == PARTNERSHIP_STATUS.CANCELED &&
    Date.now() > new Date(partnership.details["expiration_date"])
  ) {
    html = (
      <Modal opened={popup.isOpened} style={popup.style}>
        <div
          data-name="modal-overlay"
          className={classes.overlay}
          role="button"
          onClick={(event) => {
            if (event.target.matches(`[data-name="modal-overlay"]`))
              popup.onClose();
          }}
        >
          <div className={classes.card}>
            <h4 className={classes.title} style={{ fontWeight: "400" }}>
              Your {plan} partnership was <strong>canceled</strong> on{" "}
              {useDateFormat(expirationDate, "T")} due to non-payment.
            </h4>
            <nav className={classes.navigation}>
              <Button onClick={onNavigateToPartnersMain}>Renew Now</Button>
            </nav>
            <p className={classes.text}>{t("pages.library.texts.3")}</p>
          </div>
        </div>
      </Modal>
    );
  } else {
    if (
      partnership.details["status"] != PARTNERSHIP_STATUS.EXPIRED &&
      partnership.details["status"] != PARTNERSHIP_STATUS.FREE
    ) {
      if (
        partnership.details["status"] == PARTNERSHIP_STATUS.PAUSED ||
        partnership.details["status"] == PARTNERSHIP_STATUS.PAST_DUE
      ) {
        html = (
          <Modal opened={popup.isOpened} style={popup.style}>
            <div
              data-name="modal-overlay"
              className={classes.overlay}
              role="button"
              onClick={(event) => {
                if (event.target.matches(`[data-name="modal-overlay"]`))
                  popup.onClose();
              }}
            >
              <div className={classes.card}>
                <h4 className={classes.title} style={{ fontWeight: "400" }}>
                  Your partnership is <strong>past due</strong>, please{" "}
                  <Link
                    className="c-orange"
                    to={APPLICATION_ROUTES.MY_ACCOUNT.PAYMENT_METHOD}
                  >
                    click here
                  </Link>{" "}
                  to update your payment methods.
                </h4>
                <p className={classes.text}>{t("pages.library.texts.3")}</p>
              </div>
            </div>
          </Modal>
        );
      }
    } else if (partnership.details["status"] == PARTNERSHIP_STATUS.EXPIRED) {
      html = (
        <Modal opened={popup.isOpened} style={popup.style}>
          <div
            data-name="modal-overlay"
            className={classes.overlay}
            role="button"
            onClick={(event) => {
              if (event.target.matches(`[data-name="modal-overlay"]`))
                popup.onClose();
            }}
          >
            <div className={classes.card}>
              <h4 className={classes.title} style={{ fontWeight: "700" }}>
                {t("pages.library.titles.2")}
              </h4>
              <p className={classes.text}>
                Your {plan} partnership <strong>expired</strong> on{" "}
                {useDateFormat(expirationDate, "T")}.
              </p>
              <nav className={classes.navigation}>
                <Button onClick={onNavigateToPartnersMain}>Renew Now</Button>
              </nav>
              <p className={classes.text}>{t("pages.library.texts.3")}</p>
            </div>
          </div>
        </Modal>
      );
    }
  }

  return html;
};

PlanCanceled.propTypes = {
  popup: PropTypes.object,
  plan: PropTypes.string,
  expirationDate: PropTypes.Date,
};

const AuthorizationCommon = ({ popup }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { authorization } = useContext(StoreContext);

  const onNavigateToPartnersMain = () => {
    history.push(`${APPLICATION_ROUTES.PARTNERS_MAIN_HOME}?scroll=true`);
  };

  return (
    <Modal opened={popup.isOpened} style={popup.style}>
      <div
        data-name="modal-overlay"
        className={classes.overlay}
        role="button"
        onClick={(event) => {
          if (event.target.matches(`[data-name="modal-overlay"]`))
            popup.onClose();
        }}
      >
        <div className={classes.card}>
          <h4 className={classes.title}>
            Would you like to access 100&apos;s of hours
            <br />
            of Chassidishe entertainment?
          </h4>
          <nav className={classes.navigation}>
            {authorization && authorization.isAuthenticated ? (
              <Button onClick={onNavigateToPartnersMain}>
                {t("pages.library.labels.6")}
              </Button>
            ) : (
              <>
                <div>
                  {t("pages.library.labels.8")},
                  <NavLink
                    className={cs(classes.link, "ml-02")}
                    to={
                      APPLICATION_ROUTES.SIGN_IN +
                      "?redirect=" +
                      encodeURIComponent(location.pathname + location.search)
                    }
                  >
                    {t("layout.sign_in")}
                  </NavLink>
                </div>
                <div>
                  <Button onClick={onNavigateToPartnersMain}>
                    {t("pages.library.labels.6")}
                  </Button>
                </div>
              </>
            )}
          </nav>
          <p className={classes.text}>{t("pages.library.texts.3")}</p>
          <p className={classes.discountText}>{t("pages.library.texts.7")}</p>
        </div>
      </div>
    </Modal>
  );
};

AuthorizationCommon.propTypes = {
  popup: PropTypes.object,
};

export {
  Authorization,
  AuthorizationCommon,
  AuthorizationKids,
  EmptyPlaylist,
  PlanCanceled,
};
