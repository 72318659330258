import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { PAGE_TITLES } from "../../../constants";
import PageTitle from "../../atoms/PageTitle";
import Layout from "../../templates/Layout";
import classes from "./Partners.module.scss";
import cs from "classnames";
import Icon, { SIZES } from "../../atoms/Icon";
import { cards } from "./utilities";
import { Trans } from "react-i18next";
import maorPartnersIllustration from "../../../assets/images/maorPartners.jpg";
import partnersCard from "../../../assets/images/partners-card.png";
import Button from "../../atoms/Button";
import maorPartnersBanner from "../../../assets/images/partners-banner.png";
import maorPartnersBannerMobile from "../../../assets/images/partner-banner-mobile.png";
import { StoreContext } from "../../../contexts";
import { ACTIONS } from "../../../store";
import Loader from "../../atoms/Loader";
import { isMobile } from "react-device-detect";
import PartnersSection from "../../organisms/PartnersSection";
import BusinessCard from "./components/BusinessCard";
import { Element, scroller } from "react-scroll";
import clickGif from "../../../assets/gifs/cursor gif.gif";
import screenHeights from "../../../assets/images/screen_heights.png";
import partnersCardMobile from "../../../assets/images/partners-card-mobile.png";
// import verticalMaorCard from "../../../assets/images/card_vertical.png";

const Partners = observer(() => {
  const { partnership } = useContext(StoreContext);
  const [flippedFeatureCard, setFlippedFeatureCard] = useState(null);
  const [showClickGif, setShowClickGif] = useState(isMobile);
  const [flippedBusinessCard, setFlippedBusinessCard] = useState(null);
  const url = new URL(window.location.href);

  useEffect(() => {
    if (url.searchParams.get("scroll") == 'true') {
      onScrollToPartnersSection();
    }
  }, []);

  const handleCardClick = (index) => {
    if (flippedFeatureCard == index) {
      setFlippedFeatureCard(null);
    } else {
      setFlippedFeatureCard(index);
    }
  };

  const handleBusinessCardClick = (index) => {
    console.log("handleBusinessCardClick", index);
    if (flippedBusinessCard == index) {
      setFlippedBusinessCard(null);
    } else {
      setFlippedBusinessCard(index);
    }
  };

  useEffect(() => {
    if (showClickGif) {
      const timer = setTimeout(() => {
        setShowClickGif(false);
      }, 5000); // 5000 milliseconds = 5 seconds

      // Cleanup the timer when the component is unmounted
      return () => clearTimeout(timer);
    }
  }, [showClickGif]);

  useEffect(() => {
    partnership[`${ACTIONS.PARTNERSHIP.GET_ALL_BUSINESS_PARTNERS}`]();
  }, []);

  const onScrollToPartnersSection = () => {
    scroller.scrollTo("partnersSection", {
      duration: 500,
      smooth: true,
      offset: -24,
    });
  };

  return (
    <>
      <PageTitle value={PAGE_TITLES.PARTNERS} />
      <Layout logotype="partners">
        <div className={cs(classes.partnersMain)}>
          <div className={cs(classes.banner)}>
            <img
              src={maorPartnersBanner}
              alt="maorPartnersBanner"
              className={cs(classes.maorPartnersBanner, classes.mobileHide)}
            />
            <img
              src={maorPartnersBannerMobile}
              alt="maorPartnersBanner"
              className={cs(
                classes.maorPartnersBannerMobile,
                classes.browserHide
              )}
            />
            <div className={cs(classes.bannerContent)}>
              <h1 className={cs(classes.bannerTitle)}>
                A Membership <br /> that has it{" "}
                <span className={cs(classes.colorOrange)}> ALL </span>
              </h1>
              <p className={cs(classes.bannerText)}>
                Access 1000`&apos;s of Rebbe videos and audio series while
                enjoying the ultimate Partners discount card.
              </p>

              <div className={cs(classes.bannerButton)}>
                <div
                  onClick={() => onScrollToPartnersSection()}
                  className={cs(classes.joinNowButton)}
                >
                  JOIN NOW
                </div>
              </div>
            </div>
            <div className={cs(classes.bannerButton, classes.mobileHide)}>
              <div
                onClick={() => onScrollToPartnersSection()}
                className={cs(classes.joinNowButton)}
              >
                JOIN NOW
              </div>
            </div>
          </div>
          <div className={cs(classes.backgroundLight, classes.mainContent)}>
            <div className={cs(classes.partnersMainContent, "wrapper-m")}>
              <div className={cs(classes.cardContainer)}>
                {cards.map((card, index) => {
                  return (
                    <div
                      key={card.title}
                      className={cs(
                        classes.flipCard,
                        !isMobile && classes.flipCardBrower,
                        classes[card.color],
                        {
                          [classes.rotate]: flippedFeatureCard == index,
                        }
                      )}
                      onClick={() => isMobile && handleCardClick(index)}
                    >
                      <div className={cs(classes.flipCardInner)}>
                        <div className={cs(classes.flipCardFront)}>
                          <Icon
                            size={isMobile ? SIZES.XL : SIZES.XXXL}
                            name={card.logoName}
                          />
                          <h4 className={cs(classes.flipCardTitle)}>
                            {card.title}
                          </h4>
                        </div>
                        <div className={cs(classes.flipCardBack)}>
                          <div className={cs(classes.flipCardBackTop)}>
                            {card.backgroundTitle}
                          </div>
                          <p className={cs(classes.cardInfo)}>
                            <Trans
                              components={{
                                br: <br className={cs(classes.mobileHide)} />,
                              }}
                              i18nKey={card.description}
                            />
                          </p>
                        </div>
                      </div>

                      {index == 0 && isMobile && showClickGif && (
                        <div className={cs(classes.clickGifContainer)}>
                          <img
                            className={classes.clickGif}
                            src={clickGif}
                            alt="clickGif indicator"
                            draggable={false}
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>

              <div className={cs(classes.packagesInnerSec)}>
                <div className="d-flex ai-center">
                  <div className={classes.packagesInnerLeft}>
                    <div>
                      <img
                        alt="Package Banner"
                        className={classes.image}
                        draggable={false}
                        src={maorPartnersIllustration}
                      />
                    </div>
                  </div>
                  <div className={classes.packagesInnerright}>
                    <p>
                      Ten years ago, Maor began by sending a short daily Rebbe
                      video to just 50 subscribers.
                    </p>
                    <p>
                      Today, Maor inspires, B&quot;H, over{" "}
                      <strong>35,000</strong> children and adults across{" "}
                      <strong> 51</strong> countries and <strong>100+</strong>{" "}
                      schools every single week!
                    </p>
                    <p>
                      <strong>Why should you partner with Maor?</strong>
                    </p>
                    <p>
                      By partnering with Maor, you not only strengthen your
                      hiskashrus but also make it possible for Maor to expand
                      its reach.
                    </p>
                    <p>
                      Your support helps spread the Rebbe&apos;s message through
                      videos that provide Yidden around the world with the tools
                      and strength to fulfill our life mission of bringing
                      Moshiach.
                    </p>
                  </div>
                </div>
              </div>

              <div className={cs(classes.packagesInnerSecMobile)}>
                <div className={cs(classes.packagesInnerSecMobileContent)}>
                  <div className={classes.packagesInnerMobileUpper}>
                    <div>
                      <img
                        alt="Package Banner"
                        className={classes.image}
                        draggable={false}
                        src={maorPartnersIllustration}
                      />
                    </div>
                    <div className={cs(classes.packagesInnerMobileInfo)}>
                      <p>
                        Ten years ago, Maor began by sending a short daily Rebbe
                        video to just 50 subscribers.
                      </p>
                      <p>
                        Today, Maor inspires, B&quot;H, over{" "}
                        <strong className={cs(classes.lightGolden)}>
                          35,000
                        </strong>{" "}
                        children and adults across{" "}
                        <strong className={cs(classes.lightGolden)}> 51</strong>{" "}
                        countries and{" "}
                        <strong className={cs(classes.lightGolden)}>
                          100+
                        </strong>{" "}
                        schools every single week!
                      </p>
                    </div>
                  </div>
                  <div className={classes.packagesInnerMobileLower}>
                    <p>
                      <strong className={cs(classes.lightGolden)}>
                        Why should you partner with Maor?
                      </strong>
                    </p>
                    <br />
                    <p>
                      By partnering with Maor, you not only strengthen your
                      hiskashrus but also make it possible for Maor to expand
                      its reach.
                    </p>
                    <br />
                    <p>
                      Your support helps spread the Rebbe&apos;s message through
                      videos that provide Yidden around the world with the tools
                      and strength to fulfill our life mission of bringing
                      Moshiach.
                    </p>
                  </div>
                </div>
              </div>

              <div className={cs(classes.businessSection)}>
                <div className={cs(classes.businessSectionTop)}>
                  <div className={cs(classes.businessSectionTopLeft)}>
                    <div className={cs(classes.businessSectionTopInfo)}>
                      <img
                        className={cs(
                          classes.browserHide,
                          classes.mobileCardImage
                        )}
                        src={partnersCardMobile}
                        alt="partnersCardMobile"
                      />

                      <h1>
                        Get Discounts <span> Everywhere!</span>
                      </h1>
                      <p>
                        As a partner, you&apos;ll receive our ultimate discount
                        card.
                      </p>
                      <p>
                        This exclusive card unlocks incredible partner-only
                        discounts at your{" "}
                        <br className={cs(classes.mobileHide)} /> favorite
                        stores, ensuring you enjoy unparalleled savings and
                        benefits!
                      </p>
                    </div>
                    {!isMobile && (
                      <>
                        {!partnership[
                          `isLoading${ACTIONS.PARTNERSHIP.GET_ALL_BUSINESS_PARTNERS}`
                        ] ? (
                          <>
                            {partnership.businessPartners?.length > 0 && (
                              <div
                                className={cs(classes.businessCardConatiner)}
                              >
                                {partnership.businessPartners
                                  .slice(0, 4)
                                  .map((card, index) => {
                                    return (
                                      <BusinessCard
                                        key={card.name}
                                        card={card}
                                        index={index}
                                      />
                                    );
                                  })}
                              </div>
                            )}
                          </>
                        ) : (
                          <Loader />
                        )}
                      </>
                    )}
                  </div>
                  <img
                    src={partnersCard}
                    alt="partnersCard"
                    className={cs(classes.partnersCard, classes.mobileHide)}
                  />
                </div>
                <div className={cs(classes.businessCardConatiner)}>
                  {isMobile &&
                  partnership[
                    `isLoading${ACTIONS.PARTNERSHIP.GET_ALL_BUSINESS_PARTNERS}`
                  ] ? (
                    <>
                      <Loader />
                    </>
                  ) : (
                    <>
                      {partnership.businessPartners?.length > 0 &&
                        (isMobile
                          ? partnership.businessPartners
                          : partnership.businessPartners.slice(4)
                        ).map((card, index) => {
                          return (
                            <BusinessCard
                              key={card.name}
                              card={card}
                              handleBusinessCardClick={handleBusinessCardClick}
                              flippedBusinessCard={flippedBusinessCard}
                              setFlippedBusinessCard={setFlippedBusinessCard}
                              index={index}
                            />
                          );
                        })}
                    </>
                  )}
                </div>
                <div className={cs(classes.termsContainer)}>
                  <div className={cs(classes.item, classes.img)}>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://screenheights.com/"
                    >
                      <img src={screenHeights} />
                    </a>
                  </div>
                  <div className={cs(classes.item, classes.text)}>
                    <p>
                      Terms and conditions apply. Store terms prevail in case of
                      discrepancies. Discounts and offers may change without
                      notice and cannot be combined.
                    </p>
                  </div>
                </div>
                <div className={cs(classes.businessSectionBottom)}>
                  {/* <p className={cs(classes.mobileHide)}>
                    Terms and conditions apply. Discounts and offers may change
                    without notice and cannot be combined. Store terms prevail
                    in case of discrepancies.
                  </p>

                  <p className={cs(classes.browserHide)}>
                    Terms and conditions apply.Store terms prevail in case of
                    discrepancies. <br />
                    Discounts and offers may change without notice and cannot be
                    combined.
                  </p> */}
                  <div className={cs(classes.businessSectionButtonConatiner)}>
                    {!isMobile && (
                      <Button
                        size={isMobile ? SIZES.STRETCHED : SIZES.M}
                        borderRadiusPixel={30}
                        color="yellow"
                        onClick={() => onScrollToPartnersSection()}
                      >
                        Become a Partner
                      </Button>
                    )}
                    <Button
                      size={isMobile ? SIZES.STRETCHED : SIZES.M}
                      borderRadiusPixel={30}
                      color="brown"
                      onClick={() => {
                        window.open("mailto:info@maorcard.org");
                      }}
                    >
                      MaorCard Customer Service
                    </Button>
                    <Button
                      onClick={() => {
                        window.open(
                          "https://docs.google.com/forms/d/e/1FAIpQLSfhjWEdClRVjgnwkYyPPGbYhtAnhNauRFrHNFplr0--D1sQkw/viewform"
                        );
                      }}
                      size={isMobile ? SIZES.STRETCHED : SIZES.M}
                      borderRadiusPixel={30}
                      color="yellowGradient"
                    >
                      Business Sign-Up
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* PartnersFooter */}
          <Element name="partnersSection" />

          <PartnersSection />
        </div>
      </Layout>
    </>
  );
});

export default Partners;
