/* eslint-disable react/prop-types */
import React from "react";
import cs from "classnames";
import classes from "./CharacterSubmissionItem.module.scss";
import PropTypes from "prop-types";
import Modal from "../../../../organisms/Modal";
import CharactersModal from "../CharactersModal/CharactersModal";
import Icon, { NAMES } from "../../../../atoms/Icon";
import { useModal } from "../../../../../hooks";
import { observer } from "mobx-react-lite";
import { formatChildNames } from "../../WritingCompetition";

const CharacterSubmissionItem = observer(({ character, bgColor, onCharacterClick }) => {
  const charactersFormModal = useModal();

  const childNames = formatChildNames(character?.selected_childs);

  return (
    <>
      <div className={cs(classes.card)}>
        {/* Character Square */}

        <img
          className={cs(classes.characterSquare)}
          style={{
            background: "#FFFDE7",
            border: `10px solid ${bgColor}`,
            cursor: "pointer",
          }}
          src={character?.character_submission?.data?.characters_photo_preview}
          alt="sample"
          onClick={() => {
            onCharacterClick(character);
            charactersFormModal.onOpen();
          }}
        />

        {/* Character Label */}
        <div
          onClick={() => {
            onCharacterClick(character);
            charactersFormModal.onOpen();
          }}
          className={cs(classes.characterLabel, classes.grandstanderFamily)}
          style={{ backgroundColor: bgColor }}
        >
          My Characters
        </div>

        {/* Character Info */}
        <div className={cs(classes.characterInfo)}>
          <p className={cs(classes.characterName, classes.grandstanderFamily)}> {childNames || "Unnamed Character"}</p>
          <p className={cs(classes.characterLocation)}>
            {character?.location?.address && `${character.location.address} `}
          </p>
        </div>
      </div>

      <Modal opened={charactersFormModal.isOpened}>
        <div className={cs(classes.addCharacterModalConatiner)}>
          <div className={cs(classes.addCharacterModalCard)}>
            <CharactersModal />
            <div
              className={cs(classes.iconCross)}
              onClick={() => {
                charactersFormModal.onClose();
              }}
            >
              <Icon name={NAMES.X} />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
});

CharacterSubmissionItem.propTypes = {
  character: PropTypes.shape({
    color: PropTypes.string.isRequired,
    names: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
  }),
  bgColor: PropTypes.string.isRequired, // Optional prop for background color
};

export default CharacterSubmissionItem;
