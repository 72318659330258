const API_ROUTES = {
  AUTHORIZATION: {
    PASSWORD_RECOVERY_REQUEST: "/authorization/password-recovery/request",
    PASSWORD_RECOVERY_UPDATE: "/authorization/password-recovery/update",
    RESIGN_IN: "/authorization/sign-in-repeat",
    SEND_QUESTIONNAIRE: "/authorization/questionnaire/send",
    SIGN_IN: "/authorization/sign-in",
    SIGN_OUT: "/authorization/sign-out",
    SIGN_UP: "/authorization/sign-up",
    VERIFY_EMAIL: "/authorization/verify-email",
    GET_PERSONS: "/user/persons",
    GET_LATEST_GOLD_PARTNERS: "/latest-gold-partners",
    GET_ADDRESS: "/user/address",
    DELETE_ADDRESS: "/user/delete-address",
    ADD_ADDRESS: "/address/create",
    EDIT_ADDRESS: "/address/edit",
    SET_DEFAULT_ADDRESS: "/address/set-as-default",
  },
  CART: {
    ADD: "/cart/add",
    DELETE: "/cart/delete",
    EDIT: "/cart/edit",
    GET: "/cart/get",
  },
  CHECKOUT: {
    CREATE_TRANSACTION: "/checkout/transaction/create",
    UPDATE_TRANSACTION: "/checkout/transaction/update",
    CREATE_BIRTHDAY_TRANSACTION:
      "/checkout/transaction/create-birthday-transaction",
    GET_TOKEN: "/checkout/payment/token",
    GET_TRANSACTION: "/checkout/transaction/get",
    SALE: "/checkout/payment/sale",
    CREDIT: "/checkout/payment/credit",
  },
  LIBRARY: {
    GET: "/videos/library",
    VIDEO: {
      GET: "/video",
      SAVE_QUESTION_OPTION: "/video/questions/save",
    },
    COMBINED_LIBRARY: "/videos/combined-library",
  },
  PRODUCTS: {
    GET_CAMPAIGN: "/campaigns/active",
    GET_MEMBERS: "/campaigns/active/members",
    GET_ACTIVE_MEMBERS: "/active/members",
  },
  PARTNERSHIP: {
    GET: "/subscriptions/get-latest-subscription",
    UPGRADE: "/subscriptions/update",
    CANCEL: "/subscriptions/cancel",
    AUTO_RENEWAL_ON: "/checkout/payment/autorenew-on",
    GET_ALL_BUSINESS_PARTNERS: "/partner/all",
  },
  CATEGORIES: {
    GET: "/videos/categories",
  },
  TAGS: {
    GET: "/videos/tags",
  },
  HOLIDAYS: {
    GET_TODAY: "/holidays/today",
  },
  AUDIOS: {
    GET_PLAYLIST: "/audios/playlist",
  },
  GET_AUTOCOMPLETE: "/autocomplete/search",
  PLAYLIST: {
    GET: "/authorization/user-playlist",
    ADD: "/authorization/user-playlist/store",
    DELETE: "/authorization/user-playlist/delete",
    CLEAR: "/authorization/user-playlist/clear",
    ADD_SEARCH_FILTER:
      "/authorization/user-playlist/store-search-filter-videos",
  },
  WINNERS: {
    RAFFLE: "/raffle/lastwinner",
    MAOR: "/raffle/winner",
  },
  PERSONS: {
    ADD_CHILD: "/person/addchild",
    DELETE_CHILD: "/person/deletechild",
  },
  MAILINGLIST: {
    GET: "/mailinglist/get-all-user-mailinglist",
    SAVE: "/mailinglist/save",
    DELETE: "/mailinglist/delete",
  },
  PAYMENTMETHOD: {
    GET: "/checkout/payment/get-all-method",
    GET_TOKEN: "/checkout/payment/token",
    SAVE: "/checkout/payment/method-add",
    DELETE: "/checkout/payment/delete",
    MAKE_DEFAULT: "/checkout/payment/make-default",
  },
  TRACK_DONATION: {
    GET: "/track-donations",
  },
  DONATION: {
    GET: "/subscriptionsDonation/get-latest-subscription",
    CANCEL: "/subscriptionsDonation/cancel",
    GET_DONATION_PARTNERS: "/get-donating-member",
    GET_ALL_SUBSCRIPTION: "/subscriptionsDonation/get-all-subscription",
  },
  NOTIFY_ERROR: "/notify-error",
  FREE_DONOR_ACCESS: "/register-free-access",
  VIDEO_DATES: "/videos/video-for-birthday",
  AD: "/ad",
  WRITING_COMPETITON: {
    GET_DATA: "/submissions",
    GET_DATA_AUTH: "/submissions/auth",
    SUBMIT_CHARACTERS: "/submissions/character",
    GET_SUBMISSION: "/submissions/get",
    SUBMIT_SCRIPT: "/submissions/video",
    VOTE: "/submissions/vote",
  },
};

export default API_ROUTES;
