import { useCallback, useState } from "react";

const useCheckbox = ({ callback, checked: initialState = false, label = "" } = {}) => {
  const [checked, setChecked] = useState(initialState);

  const onChange = useCallback(() => {
    setChecked((checked) => {
      callback && callback({ checked: !checked });
      return !checked;
    });
  }, []);

  const onChangeValue = useCallback(
    (event) => {
      const newValue = event?.target?.value ?? !checked;
      setChecked(newValue);
      callback && callback({ checked: newValue });
    },
    [checked, callback]
  );

  return {
    checked: checked,
    setChecked: setChecked,
    label: label,
    onChange: onChange,
    onChangeValue: onChangeValue,
  };
};

export default useCheckbox;
