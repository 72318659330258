import React, { useState } from "react";
import PropTypes from "prop-types";
import cs from "classnames";
import PlainInput from "../../../../atoms/PlainInput";
import classes from "./Add.module.scss";
import useForm from "./useForm";
// import SelectR from "../../../../atoms/SelectR";
// import Button from "../../../../atoms/Button";
import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { isMobile } from "react-device-detect";
import SelectR from "../../../../atoms/SelectR";
import { useDateFormat } from "../../../../../hooks";
import Checkbox from "../../../../atoms/Checkbox";
import TextToggleCheckbox from "../../../../atoms/ToggleBox/ToggleBox";
import RadioDots from "../../../../atoms/RadioDots";

const Add = observer(({ back, onSuccess }) => {
  const {
    jewishDay,
    jewishMonth,
    jewishYear,
    gregorianDateText,
    enDay,
    enMonth,
    enYear,
    englishToJewishDate,
    jewishDate,
    jewishFullDate,
    isEnglishDate,
    languageToggleBox,
    afterShkiyaCheckbox,
    city,
    country,
    firstName,
    lastName,
    state,
    submitChild,
    isNotValid,
    success,
    successMsg,
    error,
    sex,
    isAfterShkiyaChecked,
    setIsAfterShkiyaChecked,
    isJewishDate
  } = useForm();

  useEffect(() => {
    if (success) {
      onSuccess();
    }
  }, [success, successMsg]);

  const [isCivil, setIsCivil] = useState(true);

  useEffect(() => {
    if (languageToggleBox.selectedValue === "Civil") {
      setIsCivil(true);
    } else {
      setIsCivil(false);
    }
  });

  return (
    <div className={cs(classes.container, isMobile ? "mb-05" : "")}>
      <div className={classes.section}>
        {/*<h3 className={cs(classes.title, "ta-center mb-06 c-white")}>Add Child</h3>*/}
        <div
          className={cs(
            classes.list,
            classes.details,
            isMobile ? classes.mobileDetails : ""
          )}
        >
          <div>
            <div
              className={cs(
                classes.item,
                classes.firstName,
                "d-flex gap-2 mb-06"
              )}
            >
              <PlainInput
                //   error={firstName.error}
                additionalClass="smallInput"
                id="partners-order-form-first-name"
                label={firstName.label}
                stretched
                value={firstName.value}
                onChange={firstName.onChange}
              />
              <PlainInput
                //   error={lastName.error}
                additionalClass="smallInput"
                id="partners-order-form-last-name"
                label={lastName.label}
                stretched
                value={lastName.value}
                onChange={lastName.onChange}
              />
            </div>
            <div
              className={cs(classes.item, classes.city, "d-flex gap-2 mb-06")}
            >
              <PlainInput
                //   error={city.error}
                additionalClass="smallInput"
                id="partners-order-form-city"
                label={city.label}
                stretched
                value={city.value}
                onChange={city.onChange}
              />
              <PlainInput
                //   error={state.error}
                additionalClass="smallInput"
                id="partners-order-form-state"
                label={state.label}
                stretched
                value={state.value}
                onChange={state.onChange}
              />
              <PlainInput
                //   error={state.error}
                additionalClass="smallInput"
                id="partners-order-form-country"
                label={country.label}
                stretched
                value={country.value}
                onChange={country.onChange}
              />
              {/* <SelectR
              id="partners-order-form-country"
              label={country.label}
              options={country.options}
              value={country.value}
              onChange={country.onChange}
            /> */}
            </div>
            <div
              className={cs(classes.item, classes.birthday, "d-flex gap-2 mb-06")}
            >
              <h3 className="heading-m">Gender *</h3>
              <div className={cs(classes.relative)}>
                <div className={cs(classes.inputStyle)}>
                  <RadioDots
                    horizontal
                    items={sex.options}
                    value={sex.value}
                    onChange={sex.onChange}
                    error={sex.error}
                    orangeBorderRadio={true}
                  />
                  {/* <span className={cs(classes.inputLine)}></span> */}
                </div>
                {/* <div className={cs(classes.tape)}></div> */}
                {/* <label className={cs(classes.inputLabel)}>Gender *</label> */}
              </div>
            </div>
            <div className={cs(classes.birthdayTop)}>
              <h3 className="heading-m">Birth Date</h3>

              <div className={cs(classes.birthdayOptions)}>
                <TextToggleCheckbox
                  handleInputChange={languageToggleBox.handleInputChange}
                  selectedValue={languageToggleBox.selectedValue}
                  label1="Civil"
                  label2="Jewish"
                />

                <div
                  className={cs(
                    classes.shkiyaCheckbox,
                    languageToggleBox.selectedValue === "Jewish" &&
                    classes.disabelShkiyaCheckbox
                  )}
                >
                  {isCivil ? (
                    <Checkbox {...afterShkiyaCheckbox} birthday={true}>
                      {afterShkiyaCheckbox.label}
                    </Checkbox>
                  ) : (
                    <Checkbox
                      {...afterShkiyaCheckbox}
                      checked={false}
                      birthday={true}
                    >
                      {afterShkiyaCheckbox.label}
                    </Checkbox>
                  )}
                </div>
              </div>
            </div>

            <div className={classes.dateContainer}>
              <ul className={cs(classes.list, classes.birthday)}>
                {isCivil && (
                  <div className={cs(classes.dateInputContainer)}>
                    <div className={cs(classes.datePickerEng)}>
                      <li className={classes.item}>
                        <SelectR
                          id="my-maor-order-form-month"
                          label={enMonth.label}
                          options={enMonth.options}
                          value={enMonth.value}
                          onChange={enMonth.onChange}
                          error={enMonth.error}
                        />
                      </li>
                      <li className={classes.item}>
                        <SelectR
                          id="my-maor-order-form-day"
                          label={enDay.label}
                          options={enDay.options}
                          value={enDay.value}
                          onChange={enDay.onChange}
                          error={enDay.error}
                        />
                      </li>
                      <li className={classes.item}>
                        <SelectR
                          id="my-maor-order-form-year"
                          label={enYear.label}
                          options={enYear.options}
                          value={enYear.value}
                          onChange={enYear.onChange}
                          error={enYear.error}
                        />
                      </li>
                    </div>
                    {!isEnglishDate &&
                      <div className={cs(classes.dateText)}>
                        <p>
                          {englishToJewishDate} <br />
                          <span className={cs(classes.hide)}>{jewishDate}</span>
                        </p>
                      </div>
                    }
                  </div>
                )}

                {!isCivil && (
                  <div className={cs(classes.dateInputContainer)}>
                    <div className={cs(classes.datePicker)}>
                      <li className={classes.item}>
                        <SelectR
                          id="my-maor-order-form-year"
                          label={jewishYear.label}
                          options={jewishYear.options}
                          value={jewishYear.value}
                          onChange={(value) => {
                            if (isAfterShkiyaChecked) {
                              afterShkiyaCheckbox.setChecked(false);
                              setIsAfterShkiyaChecked(false);
                            }
                            jewishYear.onChange(value);
                          }}
                        />
                      </li>
                      <li className={classes.item}>
                        <SelectR
                          id="my-maor-order-form-month"
                          label={jewishMonth.label}
                          options={jewishMonth.options}
                          value={jewishMonth.value}
                          onChange={(value) => {
                            if (isAfterShkiyaChecked) {
                              afterShkiyaCheckbox.setChecked(false);
                              setIsAfterShkiyaChecked(false);
                            }
                            jewishMonth.onChange(value);
                          }}
                        />
                      </li>
                      <li className={classes.item}>
                        <SelectR
                          id="my-maor-order-form-day"
                          label={jewishDay.label}
                          options={jewishDay.options}
                          value={jewishDay.value}
                          onChange={(value) => {
                            if (isAfterShkiyaChecked) {
                              afterShkiyaCheckbox.setChecked(false);
                              setIsAfterShkiyaChecked(false);
                            }
                            jewishDay.onChange(value);
                          }}
                        />
                      </li>
                    </div>
                    {!isJewishDate &&
                      <div className={cs(classes.dateText)}>
                        <p>
                          {useDateFormat(gregorianDateText, "/")}{" "}
                          {isAfterShkiyaChecked ? <span>{`(After Shkiya)`}</span> : ""} <br />{" "}
                          <span className={cs(classes.hide)}>{jewishFullDate}</span>
                        </p>
                      </div>
                    }
                  </div>
                )}
                {/* <li className={classes.item}>
                <PlainInput
                  error={rank.error}
                  id="my-maor-order-form-rank"
                  label={rank.label}
                  value={rank.value}
                  onChange={rank.onChange}
                />
              </li> */}
              </ul>
            </div>

            <div className={cs(classes.addBtn, "d-flex gap-2 ")}>
              <button
                className={cs(classes.backBtn, "fw-700")}
                type="submit"
                onClick={back}
              >
                Back
              </button>
              <button
                className={cs(classes.addBtn, "fw-700 c-orange")}
                type="submit"
                onClick={submitChild}
                disabled={isNotValid}
              >
                Add
              </button>
            </div>
            {error && (
              <p className="ta-center">
                <span className={cs(classes.errorMsg, "fw-700 mt-02 d-block")}>
                  There was an error in processing your request.
                </span>
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

const inputProps = PropTypes.shape({
  error: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}).isRequired;

// const selectProps = PropTypes.shape({
//   label: PropTypes.string.isRequired,
//   options: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
//   value: PropTypes.string.isRequired,
//   onChange: PropTypes.func.isRequired,
// }).isRequired;

const selectPropTypes = PropTypes.shape({
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  bool: PropTypes.bool,
  selectedValue: PropTypes.string,
}).isRequired;

Add.propTypes = {
  city: inputProps,
  country: inputProps,
  firstName: inputProps,
  lastName: inputProps,
  state: inputProps,
  onSuccess: PropTypes.func,
  back: PropTypes.func,
  jewishDay: selectPropTypes,
  jewishMonth: selectPropTypes,
  jewishYear: selectPropTypes,
  afterShkiyaCheckbox: inputProps,
  languageToggleBox: inputProps,
  enDay: selectPropTypes,
  enMonth: selectPropTypes,
  enYear: selectPropTypes,
  jewishDate: selectPropTypes,
  gregorianDateText: selectPropTypes,
  englishToJewishDate: selectPropTypes,
  jewishFullDate: PropTypes.string,
  isEnglishDate: selectPropTypes,
  sex: selectPropTypes,
};

export default React.memo(Add);
