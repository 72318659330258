import React, { useContext, useEffect } from "react";
// import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";

// import Checkout from "../../templates/Checkout";
import { StoreContext } from "../../../contexts";
// import { APPLICATION_ROUTES } from "../../../constants";
// import { ACTIONS } from "../../../store";
// import Checkout from "../Checkout";
// import useCheckout from "./useCheckout";
import Cart from "./components/Cart";
import Submit from "./components/Submit";
import Logotype, { TYPES as LOGOTYPE_TYPES } from "../../atoms/Logotype";
import { ACTIONS } from "../../../store";
import classes from "./MyMaorCheckout.module.scss";
import Loader from "../../atoms/Loader";
import cs from "classnames";
import Payment from "./components/Payment";
import Button from "../../atoms/Button";

const MyMaorCheckout = observer(() => {
  // const options = useCheckout();

  // useEffect(() => {
  //   cart.get();
  // }, []);

  // return (
  //   <div>
  //     <div>MyMaor Checkout: This page in progress!</div>
  //     <div>
  //       {cart[`isLoading${ACTIONS.CART.GET}`] ? (
  //         "Loading"
  //       ) : (
  //         <div>
  //           {cart.items.map((item) => (
  //             <div key={item.id}>
  //               <span>{item.item_id}</span>
  //               <Link to={`${APPLICATION_ROUTES.MY_MAOR_ORDER}?type=${item.item_id}&id=${item.id}`}>Edit</Link>
  //               <button
  //                 onClick={() => {
  //                   cart.delete({ id: item.id });
  //                 }}
  //               >
  //                 Delete
  //               </button>
  //             </div>
  //           ))}
  //         </div>
  //       )}
  //       <div></div>
  //     </div>
  //   </div>
  // );

  const { authorization, cart, videodates } = useContext(StoreContext);

  const isLoading = authorization[`isLoading${ACTIONS.AUTHORIZATION.RESIGN_IN}`];
  const isVideoDatesError = videodates[`error${ACTIONS.VIDEO_DATES.GET_VIDEO_DATES}`];

  useEffect(() => {
    let videoRenderCounter = sessionStorage.getItem("videoRenderCounter");
    if (isVideoDatesError && videoRenderCounter <= 3) {
      window.location.reload();
      videoRenderCounter++;
      sessionStorage.setItem("videoRenderCounter", videoRenderCounter);
    } else if (videodates[`success${ACTIONS.VIDEO_DATES.GET_VIDEO_DATES}`] && !isVideoDatesError) {
      sessionStorage.removeItem("videoRenderCounter");
    }
  }, [isVideoDatesError, videodates[`success${ACTIONS.VIDEO_DATES.GET_VIDEO_DATES}`]]);

  return (
    <div className={classes.container}>
      <div className={cs(classes.wrapper, "wrapper-xl")}>
        <nav className={classes.logotype}>
          <Logotype type={LOGOTYPE_TYPES.MY_MAOR} />
        </nav>
        <main className={classes.card}>
          <div className={cs(classes.main, classes.checkoutMain)}>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <section className={classes.cart}>
                  <Cart />
                </section>
                {cart.isNotEmpty && (
                  // <section className={classes.payment}>
                  <Payment />
                  // </section>
                )}
              </>
            )}
          </div>
          {cart.isNotEmpty && (
            <>
              {isVideoDatesError ? (
                <div className="d-flex fd-column ai-center jc-center pb-05">
                  <p className="ta-center pb-05 c-red">Something went wrong. Please try again.</p>
                  <Button onClick={() => window.location.reload()}>Reload</Button>
                </div>
              ) : (
                <div className={classes.footer}>
                  <Submit />
                </div>
              )}
            </>
          )}
        </main>
      </div>
    </div>
  );

  // return <Checkout type="child" options={options} />;
});

export default MyMaorCheckout;
