import React from "react";
import PropTypes from "prop-types";
import ScrollLock from "react-scrolllock";
import cs from "classnames";

import Portal from "../Portal";
import classes from "./MyPlaylistModal.module.scss";
import { isMobile } from "react-device-detect";
// import { useDocumentScroll } from "../../../hooks";

const MyPlaylistModal = ({
  hasPlaylist,
  children,
  flexible,
  disableOverlay: isDisabledOverlay,
  opened: isOpened,
  style,
  onClose,
}) => {
  const onClick = ({ target }) => {
    if (!isDisabledOverlay && target.dataset.name === "modal-overlay") {
      onClose && onClose();
    }
  };

  // const scroll = useDocumentScroll();

  // const scrollHidden = scroll.vertical.direction === "down" && scroll.vertical.scrolled > 100;

  return (
    isOpened && (
      <>
        <Portal selector="#modal-root">
          <ScrollLock>
            <div
              data-name="modal-overlay"
              className={cs(classes.overlay, isMobile && hasPlaylist ? classes.mobileOverlayWrap : "", {
                [classes.flex]: flexible,
                // [classes.noHeaderOverlayWrap]: isMobile && hasPlaylist && scrollHidden,
              })}
              style={style}
              onClick={onClick}
            >
              {children}
            </div>
          </ScrollLock>
        </Portal>
      </>
    )
  );
};

MyPlaylistModal.propTypes = {
  disableOverlay: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  flexible: PropTypes.bool,
  opened: PropTypes.bool,
  style: PropTypes.shape({}),
  onClose: PropTypes.func,
  hasPlaylist: PropTypes.bool,
};

MyPlaylistModal.defaultProps = {
  disableOverlay: false,
  flexible: true,
  opened: false,
  style: null,
  onClose: null,
  hasPlaylist: false,
};

export default MyPlaylistModal;
