import React, { useContext, useState } from "react";
import cs from "classnames";
import classes from "./ToichenOptions.module.scss";
import { StoreContext } from "../../../../../contexts";
import { WRITING_COMPETITON_DATA_KEY } from "../../WritingCompetition";
import tAudio from "../../../../../assets/images/t_audio.png";
import ReactJkMusicPlayer from "react-jinke-music-player";

let defaultOptions = {
  audioLists: [],
  theme: "light",
  remember: false,
  remove: false,
  autoplay: true,
  loadAudioErrorPlayNext: false,
  autoPlayInitLoadPlayList: false,
  defaultPosition: {
    right: 30,
    bottom: 5,
  },
  showDownload: false,
  showThemeSwitch: false,
  showLyric: false,
  lyric: false,
  mode: "full",
  clearPriorAudioLists: true,
  toggleMode: false,
  // APPLICATION_ROUTES.LIBRARY === window.location.pathname ||
  // APPLICATION_ROUTES.LIBRARY_PROECTED === window.location.pathname
  //   ? "full"
  //   : "mini",
};

const ToichenOptions = () => {
  const { common } = useContext(StoreContext);
  const [currentAudio, setCurrentAudio] = useState({});

  const writingCompetitionData = common.get(WRITING_COMPETITON_DATA_KEY);
  const audioHandler = {
    currentAudio: currentAudio,
    setCurrentAudio: setCurrentAudio,
  };

  // const toggleAudio = () => {
  //   if (audioHandler.currentAudio.musicSrc) {
  //     audioHandler.setCurrentAudio({
  //       musicSrc: "",
  //     });
  //   } else {
  //     audioHandler.setCurrentAudio({
  //       musicSrc: "d",
  //       showAudioList: true,
  //     });
  //   }
  // };

  return (
    <>
      <div className={cs(classes.componentContainer, classes.grandstanderFamily)}>
        <h2 className={cs(classes.header)}>TOICHEN OPTIONS:</h2>
        <div className={cs(classes.optionsContainer)}>
          {writingCompetitionData?.details?.toichen_buttons.map((option) => (
            <>
              {option.text && option.link ? (
                <div key={option.text} className={cs(classes.buttonContainer)}>
                  {option.audio && option.audio != "" && (
                    <button
                      className={cs(classes.audioButton)}
                      onClick={() => {
                        audioHandler.setCurrentAudio({
                          name: option.text,
                          musicSrc: option.audio,
                        });
                      }}
                    >
                      <img className={cs(classes.headphone)} src={tAudio} />
                    </button>
                  )}
                  <button
                    onClick={() => {
                      window.open(option.link, "_blank");
                    }}
                    className={cs(classes.button, classes.linkButton)}
                  >
                    {option.text}
                  </button>
                </div>
              ) : (
                ""
              )}
            </>
          ))}
        </div>
      </div>
      {audioHandler.currentAudio.musicSrc && (
        <ReactJkMusicPlayer
          {...defaultOptions}
          audioLists={[
            {
              name: audioHandler.currentAudio.name,
              musicSrc: audioHandler.currentAudio.musicSrc,
              cover: "",
            },
          ]}
          sortableOptions={{
            swap: false,
            sort: false,
            animation: 100,
            disabled: true,
            swapClass: "audio-lists-panel-sortable-highlight-bg",
          }}
          responsive={false}
          playIndex={audioHandler.currentAudio.index}
          autoPlay={true}
          showDestroy={true}
          autoPlayInitLoadPlayList={false}
          clearPriorAudioLists={true}
          onBeforeDestroy={() => {
            return new Promise((resolve, reject) => {
              audioHandler.setCurrentAudio({});
              reject();
            });
          }}
          renderAudioTitle={(audioInfo) => {
            return <span className={classes.audioTitle}>{audioInfo.name}</span>;
          }}
        />
      )}
    </>
  );
};

export default ToichenOptions;
