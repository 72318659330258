import { ACTIONS as AUTHORIZATION } from "./authorization/utilities";
import { ACTIONS as CART } from "./cart/utilities";
import { ACTIONS as CHECKOUT } from "./checkout/utilities";
import { ACTIONS as LIBRARY } from "./library/utilities";
import { ACTIONS as PLAYLIST } from "./playlist/utilities";
import { ACTIONS as PRODUCTS } from "./products/utilities";
import { ACTIONS as AUDIOS } from "./audios/utilities";
import { ACTIONS as PARTNERSHIP } from "./partnership/utilities";
import { ACTIONS as WINNERS } from "./winners/utilities";
import { ACTIONS as PERSONS } from "./persons/utilities";
import { ACTIONS as MAILINGLIST } from "./mailinglist/utilities";
import { ACTIONS as PAYMENTMETHOD } from "./paymentmethod/utilities";
import { ACTIONS as TRACK_DONATION } from "./trackdonation/utilities";
import { ACTIONS as ERROR } from "./error/utilities";
import { ACTIONS as DONATION } from "./donation/utilities";
import { ACTIONS as FREE_DONOR_ACCESS } from "./freedonoraccess/utilities";
import { ACTIONS as VIDEO_DATES } from "./videodates/utilities";
import { ACTIONS as AD } from "./ad/utilities";
import { ACTIONS as WRITING_COMPETITON } from "./writingCompetition/utilities";

import store from "./store";

const ACTIONS = {
  AUTHORIZATION: AUTHORIZATION,
  CART: CART,
  CHECKOUT: CHECKOUT,
  LIBRARY: LIBRARY,
  PLAYLIST: PLAYLIST,
  PRODUCTS: PRODUCTS,
  AUDIOS: AUDIOS,
  PARTNERSHIP: PARTNERSHIP,
  WINNERS: WINNERS,
  PERSONS: PERSONS,
  MAILINGLIST: MAILINGLIST,
  PAYMENTMETHOD: PAYMENTMETHOD,
  TRACK_DONATION: TRACK_DONATION,
  ERROR: ERROR,
  DONATION: DONATION,
  FREE_DONOR_ACCESS: FREE_DONOR_ACCESS,
  VIDEO_DATES: VIDEO_DATES,
  AD: AD,
  WRITING_COMPETITON: WRITING_COMPETITON,
};

export { ACTIONS };
export default store;
