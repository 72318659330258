import React, { useContext } from "react";
import cs from "classnames";
import classes from "./CharactersModal.module.scss";
import CharacterItem from "./components/CharacterItem";
import PropTypes from "prop-types";
import { StoreContext } from "../../../../../contexts";
import { observer } from "mobx-react-lite";
import { formatChildNames } from "../../WritingCompetition";

const CharactersModal = observer(() => {
  const { common } = useContext(StoreContext);

  const characterData = common.get("currentCharacter");

  const childNames = formatChildNames(characterData?.selected_childs);
  const setting = characterData?.character_submission?.data?.setting;

  return (
    <div className={cs(classes.characterFormModal)}>
      <div className={cs(classes.headerCharacter)}>
        <h2 className={cs(classes.heading, classes.grandstanderFamily)}>Writers:</h2>
        <h2 className={cs(classes.heading, classes.grandstanderFamily, classes.red)}>{childNames}</h2>
        <p className={cs(classes.red)}>
          From {characterData?.location?.address && `${characterData.location.address} `}
          {/* {characterData?.location?.state && `, ${characterData.location.state}, `} */}
          {/* {characterData?.location?.country} */}
        </p>
      </div>

      <div className={cs(classes.bodyCharacter)}>
        <h2 className={cs(classes.heading, classes.grandstanderFamily)}>Characters</h2>

        <div className={cs(classes.characterItemsContainer)}>
          {characterData?.character_submission?.data?.characters.map((character, i) => (
            <CharacterItem key={i} character={character} />
          ))}
          <div></div>
        </div>
      </div>

      <div className={cs(classes.bodyCharacter)}>
        <h2 className={cs(classes.heading, classes.grandstanderFamily)}>Settings</h2>

        <div className={cs(classes.characterSettingContainer)}>
          {setting?.location && (
            <p>
              <strong>Name of place:</strong> <span>{setting.location.toLowerCase()}</span>
            </p>
          )}
          {setting?.place_type && (
            <p>
              <strong>{setting.location} is a:</strong> <span>{setting.place_type.toLowerCase()}</span>
            </p>
          )}
          {setting?.where_in_world && (
            <p>
              <strong>Where in the world is {setting.location}:</strong>
              <span>{setting.where_in_world.toLowerCase()}</span>
            </p>
          )}
          {setting?.weather && (
            <p>
              <strong>What is the weather like in {setting.location}?</strong>{" "}
              <span>{setting.weather.toLowerCase()}</span>
            </p>
          )}
          {setting?.storyTime && (
            <p>
              <strong>When does the story of {setting.location} take place?</strong>{" "}
              <span>{setting.storyTime.toLowerCase()}</span>
            </p>
          )}
          {setting?.unique_aspects && (
            <p>
              <strong>What makes {setting.location} special?</strong>{" "}
              <span>{setting.unique_aspects.toLowerCase()}</span>
            </p>
          )}
          {setting?.view && (
            <p>
              <strong>What is the view in {setting.location}?</strong> <span>{setting.view.toLowerCase()}</span>
            </p>
          )}
          {setting?.transportation && (
            <p>
              <strong>How do the people of {setting.location} get around?</strong>{" "}
              <span>{setting.transportation.toLowerCase()}</span>
            </p>
          )}
          {setting?.rules_and_traditions && (
            <p>
              <strong>Are there any special traditions in {setting.location}?</strong>{" "}
              <span>{setting.rules_and_traditions.toLowerCase()}</span>
            </p>
          )}
          {setting?.additional_info && (
            <p>
              <strong>Tell us more about {setting.location}:</strong>
              <span>{setting.additional_info.toLowerCase()}</span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
});

CharactersModal.propTypes = {
  characterData: PropTypes.object,
};

export default CharactersModal;
