import React, { useContext, useState } from "react";
import Icon from "../../../atoms/Icon";
import ModalMenu from "../../Header/components/ModalMenu";
import classes from "./Header.module.scss";
import cs from "classnames";
import { APPLICATION_ROUTES } from "../../../../constants";
import AsyncAutocompleteSearch from "../../../atoms/AsyncAutocompleteSearch";
import { observer } from "mobx-react-lite";
import { getImage } from "../../../atoms/Logotype/utilities";
import ProfileMenu from "../../../molecules/ProfileMenu";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import Popover, {
  POSITIONS as POPOVER_POSITIONS,
  usePopover,
} from "../../../organisms/Popover";
import GlobalNavigation, {
  DIRECTIONS,
} from "../../../molecules/GlobalNavigation";
import { useHistory } from "react-router-dom";
import { StoreContext } from "../../../../contexts";
import { useEffect } from "react";
import arrowUp from "../../../../assets/svg/arrow-up.svg";
import arrowDown from "../../../../assets/svg/arrow-down.svg";
// import PromotionalBanner from "../../PromotionalBanner/PromotionalBanner";
import { scrollToTop } from "../../../../utilities/helpers";
import MaorCredit from "../../../molecules/MaorCredit";
// import ReferralBanner from "../../ReferralBanner";

const Header = observer(({ autocomplete, logotype }) => {
  const history = useHistory();
  const { search, searching } = autocomplete;
  // const { authorization } = useContext(StoreContext);
  const { common } = useContext(StoreContext);
  const [searchShown, setSearchShown] = useState(false);
  const [redirectLibrary, setRedirectLibrary] = useState(APPLICATION_ROUTES.MY_MAOR_LIBRARY);

  let img = getImage(logotype);

  if (location.href.includes(APPLICATION_ROUTES.LIBRARY)) {
    img = getImage(common.get("project"));

    let currentUrl = location.href;
    if (currentUrl.includes(APPLICATION_ROUTES.MY_MAOR_LIBRARY)) {
      common.set("project", "my-maor");
    }
    if (currentUrl.includes(APPLICATION_ROUTES.DAILY_REBBE_VIDEO_LIBRARY)) {
      common.set("project", "daily-rebbe-video");
    }
    if (currentUrl.includes(APPLICATION_ROUTES.ILLUMINATE_LIBRARY)) {
      common.set("project", "illuminate");
    }
  }

  let currentPage = location.href;

  useEffect(() => {
    if (currentPage.includes(APPLICATION_ROUTES.DAILY_REBBE_VIDEO_MAIN) || currentPage.includes(APPLICATION_ROUTES.DAILY_REBBE_VIDEO_LIBRARY)) {
      setRedirectLibrary(APPLICATION_ROUTES.DAILY_REBBE_VIDEO_LIBRARY)
    } else if (currentPage.includes(APPLICATION_ROUTES.ILLUMINATE) || currentPage.includes(APPLICATION_ROUTES.ILLUMINATE_LIBRARY)) {
      setRedirectLibrary(APPLICATION_ROUTES.ILLUMINATE_LIBRARY)
    } else {
      setRedirectLibrary(APPLICATION_ROUTES.MY_MAOR_LIBRARY)
    }
  }, [currentPage])

  useEffect(() => {
    if (
      location.pathname != APPLICATION_ROUTES.LIBRARY ||
      location.search.length > 0
    ) {
      common.set("selectProjectScreen", false);
    }
  }, []);

  let id = "site-menu";

  const dropdown = usePopover({
    position: POPOVER_POSITIONS.RIGHT_BOTTOM_OUT,
    selector: `#${id}`,
  });

  // const navigateToLibrary = () => {
  //   // history.push(APPLICATION_ROUTES.LIBRARY);
  //   window.open(APPLICATION_ROUTES.LIBRARY, "_self");
  // };

  return (
    <>
      {/* <PromotionalBanner /> */}
      {/* <ReferralBanner /> */}
      <section className={cs(classes.mobileHeader)}>
        {/* {searchShown && ( */}
        <div
          className={cs(classes.mobileHeaderSearch, {
            [classes.hidden]: !searchShown,
          })}
        >
          <Icon
            onClick={() => {
              setSearchShown(false);
            }}
            color="primary"
            name="arrow-in-circle"
          />
          <AsyncAutocompleteSearch
            id="async-autocomplete-search"
            page="mobileSearchLayout"
            placeholder="Search Library"
            value={search.value}
            onSearch={searching.onSearch}
            searchingOptions={searching.options}
            isSearching={searching.isSearching}
            onChange={(value) => {
              search.onChange(value);
              if (autocomplete.onNavigateToSearch) {
                autocomplete.onNavigateToSearch(false, value, redirectLibrary);
              }
            }}
          >
            <Icon
              cursorPointer={true}
              onClick={() => {
                let searchValue = document.querySelector(".rbt-input-main")
                  .value;
                if (autocomplete.onNavigateToSearch) {
                  autocomplete.onNavigateToSearch();
                }
                if (searchValue != "") {
                  search.onChange(searchValue);
                  if (autocomplete.onNavigateToSearch) {
                    autocomplete.onNavigateToSearch(false, searchValue);
                  }
                }
              }}
              name="search"
            />
          </AsyncAutocompleteSearch>
        </div>
        {/* )} */}

        {/* {!searchShown && ( */}
        <>
        <div className="d-flex ai-center content-center">
        <p className={classes.iAmText}>ב”ה</p>

          <div
            onClick={() => {
              if (location.href.includes(APPLICATION_ROUTES.LIBRARY)) {
                // location.reload();
                common.set(
                  "selectProjectScreen",
                  !common.get("selectProjectScreen")
                );
              } else {
                history.push(APPLICATION_ROUTES.LIBRARY);
              }
            }}
            className={cs(classes.mobilelogo, {
              [classes.hidden]: searchShown,
            })}
          >
            <img src={img} />
            {location.href.includes(APPLICATION_ROUTES.LIBRARY) ? (
              <>
                {common.get("selectProjectScreen") ? (
                  // <Icon size="xs" name="arrow-up" />
                  <img style={{ width: "20px" }} src={arrowUp} />
                ) : (
                  // <Icon size="xs" name="arrow-down" />
                  <img style={{ width: "20px" }} src={arrowDown} />
                )}
              </>
            ) : null}
          </div>
          </div>
          <div
            className={cs(classes.mobileIcons, {
              [classes.hidden]: searchShown,
            })}
          >
            {/* <Icon
              onClick={() => {
                setSearchShown(true);
              }}
              name="search"
            />
            <Icon onClick={navigateToLibrary} size="l" name="libraryicon" /> */}
            <MaorCredit />
            <ProfileMenu id="modal-menu-profile-menu" />

            {isMobile ? (
              <MobileView>
                <div
                  className={classes.menu}
                  id="site-menu"
                  onClick={dropdown.open}
                >
                  <div className={classes.familyWrapper}>
                    <div className={classes.avatar}>
                      <Icon name="hamburger" />
                    </div>
                  </div>
                </div>
                <Popover close={dropdown.close} style={dropdown.style}>
                  <div className={cs(classes.dropdown, "bxsh-hard")}>
                    <GlobalNavigation
                      direction={DIRECTIONS.VERTICAL}
                      onClick={() => {
                        common.set("selectProjectScreen", true);
                        scrollToTop();
                      }}
                    />
                  </div>
                </Popover>
              </MobileView>
            ) : (
              <BrowserView>
                <ModalMenu />
              </BrowserView>
            )}
          </div>
        </>
        {/* )} */}
      </section>
    </>
  );
});

export default Header;
