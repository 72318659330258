/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect } from "react";
import PlainInputCharacter from "../../../../atoms/PlainInputCharacter";
import cs from "classnames";
import classes from "./Location.module.scss";
import { CHARACTER_SUBMISSION_KEY } from "../../WritingCompetitionForm";
import { StoreContext } from "../../../../../contexts";
import { observer } from "mobx-react-lite";

const Location = observer(({ characterFormLocation }) => {
  const { common } = useContext(StoreContext);

  const submissionData = common.get(CHARACTER_SUBMISSION_KEY);
  const location = submissionData.location ?? null;

  useEffect(() => {
    if (location) {
      let locationInputMap = {
        address: characterFormLocation,
      };

      Object.keys(location).forEach((key) => {
        if (locationInputMap[key]) {
          locationInputMap[key].onChange({
            target: { value: location[key] },
            touched: false,
          });
        }
      });
    }
  }, []);

  return (
    <div className={cs(classes.locationContainer)}>
      <div className={cs(classes.characterFieldContainer, characterFormLocation.error && classes.errorbg)}>
        <label htmlFor="place-name-input">
          Where do you live? <span className={cs(classes.red)}> * </span>
        </label>
        <PlainInputCharacter
          error={characterFormLocation.error}
          id="place-name-input"
          label={characterFormLocation.label}
          value={characterFormLocation.value}
          onChange={characterFormLocation.onChange}
          placeHolder="Short Answer text"
        />
      </div>
    </div>
  );
});

export default Location;
